.translated-phrase{
    text-align: left;
    padding:0;

    
}


h2.translater-title{
    font-size:20px;
    margin:20px;
    color:#383838;
    text-transform: none;
    letter-spacing: 0.8px;
}

.table1{
    background-color: lightgreen;
}

h3.translated-phrase{
    color: #555555;
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: none;
    /* font-family: 'Circular Std Bold'; */
    margin:0;
 
}