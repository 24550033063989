

.api-body{
    /* background-color: #df2e2e;     */
    padding:2em;
    border-radius: 30px;
    max-width: 420px;
    margin:auto;
    align-items: center;
    justify-content: center;
    display:flex;
    width:50%;

  
}

/* .api-card{
    padding:2em;
    border-radius: 30px;
    max-width: 420px;
    background:linear-gradient(rgb(47,150,163), rgb(48,62,143));
    color:white;
    align-items: center;
    justify-content: center;
    display:flex;
    margin:auto;
    width:50%;
    }
     */


.api-card{
    /* padding:2em; */
        /* padding:25px; */

    border-radius: 30px;

    color:white;
    align-items: center;
    justify-content: center;
    display:flex;
    /* flex-direction: column; */
    justify-content: space-evenly;
    column-gap: 5px;
    margin:auto;
    margin-bottom: 15px;
    background: rgb(229, 147, 240);
    width:100%;



        /* max-width: 420px; */
    /* background:linear-gradient(rgb(47,150,163), rgb(48,62,143));  */
    /* max-width: 800px; */

}