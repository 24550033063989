.footer {
    left: 0;
    bottom: 0;
    width: auto;
    text-align: center;
    clear: both;
    position: relative;
    border-top: .5px solid rgb(221, 221, 221);
background-color: transparent;
margin-top:10%;
}

  
.social-link{
      width:50px;
      height:50px;
      object-position: center;
      margin-left: auto;
      margin-right: auto;
  }
  
  .footer img{
    padding-bottom: auto;

  }

  .footer p{
    font-size:10px;
    text-align: center;
    padding-bottom:0;
    height:50%;
    padding-top:0px;
    margin-top:0;
  }

  .footer-section{
  align-items: center;
  padding-left: auto;
  padding-right: auto;

  }