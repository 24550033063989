.backpack1{
  background-color: rgba(218, 242, 247, 0.705);
  background-color: rgb(235, 244, 252);
  background-color: rgb(233, 243, 247);

  background-color:rgb(194, 194, 194);

  background-color:rgb(250, 250, 250);

}

.overview-container{
  background-color:white;
  width:100%;
  border-radius: 32px;
  text-align: left;
  flex-direction: column;
  
  height:auto;
  align-items: flex-start;
  padding-left:20px;
  
  -webkit-box-align: center;
  background-color: white;
  padding: 20px 40px 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left:8px;
  padding-right:8px;
  
  margin-left: auto;
  margin-right: auto;
  padding-right: 10px;
  padding-left: 10px;
  
  
  box-shadow: rgb(209 230 255 / 30%) 0px 2px 12px 1px;
  }
  

.container{
background-color:white;
width:100%;
border-radius: 32px;
text-align: left;
flex-direction: column;

height:auto;
align-items: flex-start;
padding-left:20px;

-webkit-box-align: center;
background-color: white;
padding: 20px 40px 20px;
padding-top: 20px;
padding-bottom: 20px;
padding-left:8px;
padding-right:8px;

margin-left: auto;
margin-right: auto;
padding-right: 10px;
padding-left: 10px;


margin-bottom: 50px;
box-shadow: rgb(209 230 255 / 30%) 0px 2px 12px 1px;
}



.sub-container{
  width:100%;
  border-radius: 32px;
  text-align: left;
  flex-direction: column;
  
  height:auto;
  align-items: flex-start;
  
  -webkit-box-align: center;
  padding: 20px 0px 20px;
  margin-bottom: 50px;
  box-shadow: rgb(209 230 255 / 40%) 0px 1px 6px 1px;
  }

.table-container{
    width:100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
    margin:auto;
    align-items: center;
    padding-left: 10px;
    padding-right:10px;
    text-align: left;
  }

  
.grid{
  width:100%;  
  text-align:center;  
  align-items: center;

}
.grid img{
  object-fit: cover;
  width:900px;
  height:100%;
  margin-bottom: 10px;
  }

  .row {
    display: flex;
    object-fit:contain;
    margin-bottom: 20px;
    width:100%;
    flex-direction: row;
    grid-column-gap: 40px;
    padding-left: 0px;

  }
  

  .col {
    display:flex;
    width:330px;
    height: 430px;
    margin-left: auto;
    margin-right: auto;
    object-fit: fill;
  }
  .col img{

     border:1px solid black;
  }


.container img{
  height:auto;
}

.container text{   
  color:#555555;
  font-size: 18px;
  letter-spacing: 0.38px;
  line-height: 22px;
  text-align: center;
  font-family: 'Circular Std Bold';
  margin-bottom: 10px;
  line-height: 40px;
  }


.wireframe{
  width:100%;
  height:auto;
  margin-top:20px;
}
.user-persona{
  height: 100%;
    width:100%;
    display: block;
    margin-bottom: 15px;
    box-sizing: border-box;
    border:solid 1px black;
    border-radius: 20px;
}

.img.user-persona{
  height: 100%;
  width:75%;
  object-fit: cover;
  display: block;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;

}
.flow-img{
  height: 100%;
    width:100%;
    object-fit: cover;
    display: block;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 15px;
    padding-top: 10px;
}


  item-caption{
      font-size:  18px;;
      text-align: center;
  }
  .item img{
    object-fit: cover;
    width:90%;
    

  }

  .single-photo{
        width:444.5px;
        height:316.66px;
        align-items: center;
  }

  .highlight-text.shown{
    background-image: linear-gradient(to right, transparent 50%, #FABB5460 50%);
    position: relative;
    background-origin: 0;
    background-size: 200% 100%;
    background-repeat: repeat-x;
    background-position: -100% 100%;
    transition: background-position 0.8s ease-out;
    padding:2px 0px;
    
  }

  .highlight.shown {
    position: relative;
    background-origin: 0;
    background-size: 200% 100%;
    background-repeat: repeat-x;
    background-position: -100% 100%;
    transition: background-position 0.8s ease-out;
    padding:4px 0px;
  }

/* Text Styles */
.tag{
  align-self: flex-start;
  border-radius: 15px;
  padding: 10px 18px 7px;
  background: rgb(231, 221, 253);
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(90, 3, 185);
  font-family: 'Circular Std Bold';
  margin-left: 10px;

}

.tag.blue{
  background: rgb(219, 240, 255);
  color: rgb(0, 132, 232);




}
h2{
  color: #555555;
  font-size:24px;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Circular Std Bold';
  margin:50px 0 25px;
}
h3{
  color: #555555;
  font-size:24px;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Circular Std Bold';
  margin:50px 0 25px;
  padding-left:15px;
  padding-bottom: 15px;


}
h4.process{
margin-bottom: 16px;
font-family: 'Circular Std Bold';
font-size: 20px;
color: #555555;
letter-spacing: 0.8px;

}

span{
  
  margin-top: 500px;
  padding-top: 50px;
  display:inline;
  
}

p{
  color: #3b3b3b;
  padding-left: 20px;
  padding-top:15px;
  padding-bottom:15px;
  font-family: 'Poppins';
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 40px;
  text-align: left;


    }
li{
      font-family: 'Poppins';
      font-size:14px;
      letter-spacing: 1.5%;
      line-height: 25px;
      color: #3b3b3b;
      padding-left: 10px;
      text-align: left;
      padding-bottom:10px;
      letter-spacing:1px;
      padding-top:20px;
      padding-left:20px;
      height:auto;

    }

    .heading-title{
      color: #535353;
      font-size:16px;
      font-weight: 100;
      letter-spacing: 2px;
      text-align: left;
      text-transform: uppercase;
      font-family: 'Circular Std Bold';
      padding-top:15px;        
      padding-left: 20px;
      padding-bottom:15px;
      padding-right:20px;
      letter-spacing: 0.50px;

   }

   @font-face{
      font-family:'Circular Std Bold';
      src:local(Circular Std Bold), url(../fonts/Circular-Std-Bold.ttf) format('truetype');
    }
    @font-face{
      font-family:'Circular Std';
      src:local(Circular Std), url(../fonts/Circular-Std.otf) format('truetype');
    }

    @font-face{
      font-family:'Poppins';
      src:local(Poppins), url(../fonts/Poppins.ttf) format('truetype');
    }