.page-container {
    width: 100%;
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1124px;
  }

  .layout{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0px auto;
}
  
  .page-contents{

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0px auto;
  }
  

  @media screen and (min-width:768px)and ( max-width: 1023px) {

  }

  