.weather-card{

       
    border-radius: 30px;
 
    color:white;
    align-items: center; 
    justify-content: center;
    display:flex;
    margin:0;

    box-shadow: rgb(209 230 255 / 57%) 0px 12px 12px 1px;
    box-shadow: rgba(79, 134, 201, 0.57) 0px 6px 12px 1px;

    width:100%;
    /* margin-bottom: 15px; */
    height: auto;
    object-fit: contain;
    
    }



.weather-body{
    background-color: #eaeaea;    
    /*padding:1em; */
    /* padding-bottom: 1em; */
    border-radius: 30px;
    /* margin:auto; */
    width:100%;
    align-items: center;
    justify-content: center;
   
background:linear-gradient(rgb(98, 183, 194), rgb(115, 122, 175)); 

}


.input-box{
    justify-content: center;
    align-items: center;
    display:flex;
    
}


.weather-input{
    border-radius: 22px;
    align-items: center;
    border: 1px;
    height:20px;
    font-family: 'Poppins';
    font-weight: 400;
    /*font-size:12px; */
    text-align:left;
    padding-left: 7px;
}


                                /* Text  & image Styling */

img.w-icon{
    display:block;
    width:auto;
    margin-left:0;
    align-items: center;
    justify-content: center;
    margin: auto;
}


h4.current-weather{
    font-family: 'Circular Std Bold';
    font-size: 20px;
    color:white;
    letter-spacing: 0.8px;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    /* color:rgb(0, 0, 0); */
}

h4.city{
    font-family: 'Circular Std Bold';
    font-size: 22px;
    color: #555555;
    letter-spacing: 0.8px;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin:10px;
    color:white;
}
h3.temp{
    font-size: 24px;
    text-align: center;
    align-items: center;
    margin:20px;
    letter-spacing: 2px;
    color:rgb(255, 251, 251);
    padding:0;
}

.weather-condition{
    font-family: 'Circular Std Bold';
    text-align: center;
    justify-content: center;
    color:black;
    color:rgb(240, 240, 240);
    font-size: 16px;

}


                                /* Weather Details */
.weather-detail-container{
    
    display:flex;
    border-radius: 12px;
    justify-content: space-evenly;
    border-radius: .5em;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    

 

}

.weather-detail{
    grid-template-columns: repeat(3, 1fr);

    font-family: 'Circular Std Bold';
    font-size: 14px;
    color:#414040;
    /* background-color: beige; */
    max-height: 20;
    width:100%;
    border: 1px solid rgb(218, 218, 218);
    border-radius: .6em;
    border-radius: 16px;
    padding: 20px;
    column-count: 3;
    column-gap: 20px;

    color: black;
}

    .item{
        display: flex;
        flex-direction: column;
        font-size: 20px;
    }

    .item-info{
        align-items: center;
        margin:auto;
    }

    .item-weather-info{
        display:flex;
        text-align: center;
        font-size: 16px;
        justify-content: center;
        margin:auto;
        color:white;
        padding:5px;
        /* width:100%; */
    }
    .humidity{
        display:flex;
        justify-content: center;
        font-size: 14px;
        color:rgb(240, 240, 240);


    }

    .feels{
        display:flex;
        justify-content: center;
        font-size: 14px;
        color:rgb(240, 240, 240);
    }

    .wind{
        display:flex;
        justify-content: center;
        font-size: 14px;
        color:rgb(240, 240, 240);

    }
    


    @media screen and (max-width: 767px) {
        .weather-card{
            height:100%;
            width:60%;
          }
    }


