

.navbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    align-items: center;
    /* border-bottom: 3px solid #f2f2f2; */
    height: 150px;
    box-sizing: border-box;
  border-radius: 0px 0px 45px 45px;
    width: 100%;
    margin:auto;
    position: relative;
    margin-bottom: 80px;
 
   }
  


  .navbar-container {
    display: flex;
    border-radius: 0px 0px 45px 45px;
    height:100%;


  }

.site-title{
  font-family: 'Circular Std bold';
  font-size:32px;
  font-weight: 700;
  text-align: bottom;
  color: rgb(53, 53, 53);
  margin-top: auto;
  margin-bottom: auto;
  color:#424242;
}


  .navbar-logo {
    display:flex;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    width:100%;
    margin-top: auto;
    margin-bottom: auto;
  

  

    
  }
  

  .side-tag-container{
    margin-top: auto;
    margin-bottom: auto;
  }

  
  side-tag{
    font-weight: 100;
    font-style: normal;
    font-size: 12px;
    line-height: 2em;
    letter-spacing: .03em;
    text-transform: none;
    color: #aaa;
    padding-left: 8px;
    text-align:left;
    font-family: 'Poppins';

  }

  .fa-typo3 {
    margin-left: 0.1rem;
    font-size: 1.8 rem;
    margin-top: 50px;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: end;
    align-content: center;
  position: relative;
  height:75%; 
  margin-top: auto;
  margin-bottom: auto;
  object-position: center;
  }
  
  .nav-item {

  height:100%;
  padding-bottom: 0;
  padding-top:0;
  padding-left: 25px;
  padding-right:auto;
  
  }
  

  li{
    padding-bottom: 0px;
  }
  
  .nav-links {
    font-family: 'Circular Std Bold';

    color:#424242;
    display: flex;
    align-items: right;
    text-decoration: none;
    font-size: 16px;



  }
  
  .nav-links:hover {
    border-bottom: px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  

/*Responsive Mobile */
@media screen and (max-width: 767px) {
  .navbar-container{
    padding: 10px 10px 10px;
  }
  .site-title{
    font-size:22px;

  }

  .side-tag-container{
    margin-top: auto;
    margin-bottom: auto;
    display:flex;

  }

  side-tag{
    font-size: 10px;
    bottom:0;

  }

  .nav-links{
    font-size: 14px;
  }

}
/*Responsive Tablet */
  @media screen and (min-width:768px)and ( max-width: 1023px) {
      .navbar {
        /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
        align-items: center;
        /* border-bottom: 3px solid #f2f2f2; */
        height: 150px;
        box-sizing: border-box;
      border-radius: 0px 0px 45px 45px;
        width: 100%;
        margin:auto;
        position: relative;
        margin-bottom: 9%;
      }

 
  .site-title{
    font-family: 'Circular Std bold';
    font-size:32px;
    font-weight: 700;
    text-align: bottom;
    color: rgb(53, 53, 53);
    margin-top: auto;
    margin-bottom: auto;
    color:#424242;
  }

  .side-tag-container{
    margin-top: auto;
    margin-bottom: auto;
  }

  side-tag{
    font-weight: 100;
    font-style: normal;
    font-size: 12px;
    line-height: 2em;
    letter-spacing: .03em;
    text-transform: none;
    color: #aaa;
    padding-left: 8px;
    text-align:center;
    font-family: 'Poppins';

  }

 
    .nav-menu.active {
      background: #ffffff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: relative;
      top: 0;
      left: 0;
     /* transform: translate(25%, 50%); */
      
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      /* transform: translate(-100%, 60%); */
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }


