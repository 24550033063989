
.currency-card{

    padding:2em;
    border-radius: 30px;
    /* max-width: 420px; */
    /* background:linear-gradient(rgb(47,150,163), rgb(48,62,143));  */

    color:white;
    align-items: center;
    justify-content: center;
    display:flex;
    margin:auto;
    background: brown;
}

.currency-converter{
    box-sizing: border-box;
    border-radius: 22px;
    border:1px solid black;
    position: relative;
    margin:auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: center;
    justify-content: center;
    background-color:#eaeaea;
    width:100%;
}


h2.crypto-title{
    font-size:20px;
    margin:20px;
    color:#383838;
    text-transform: none;
    letter-spacing: 0.8px;
}

.Pcurrency{
    font-family: 'Circular Std Bold';
    color: #555555;
}

.table{
    margin-top: 10px;
    margin-bottom: 10px;
}

td{
    color:black
}

.convert-button{
    display: flex;
    margin: auto;
}


                    /*     Exchange rate */

.exchange-rate{
    width:100%;
    color:#555555;

}

h1.exchange-details{
    font-size: 16px;
    color:#555555;
    width:100%;
    text-align: left;
    padding-left: 0;
}
