
  h1{
    color:#555555;
    font-size: 20px;
    padding-left: 20px;
}

  
  /* Style the button and place it in the middle of the container/image */
  .container .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color:transparent;
    color: rgb(255, 255, 255);
    font-size: 75px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .container .btn:hover {
      color:rgb(226, 226, 118);
  }


  .home-tag{
    align-self: flex-start;
    border-radius: 15px;
    padding: 10px 14px 7px;
    background: rgb(231, 221, 253);
    font-size: 14px;
    font-weight: 600;
    margin-left:0px;
    margin-top:20px;
    margin-bottom:20px;

    font-family: 'Circular Std Bold';
    display:inline;

  }
  
  .home-tag.blue{
    background: rgb(219, 240, 255);
    color: rgb(0, 132, 232);
  }


  .home-intro{
    padding-top:0;
    margin-top: 0;
    margin-bottom: 0;
    text-align:left;
    margin-bottom: 100px;
    padding-left:0;

  }

  .home-intro p{
    padding-left:0;
    padding-bottom:0;
    padding-top:5px;
  }

a{
  text-decoration: none;

}

.section{
 margin-bottom: 50px;
}




  @media screen and (min-width:768px)and ( max-width: 803px) {
 




  }
  
  
  
  @media screen and (max-width: 767px) {


   .home-tag.blue{
    background: rgb(219, 240, 255);
    color: rgb(0, 132, 232);
  }


  .home-tag{
    align-self: flex-start;
    border-radius: 15px;
    padding: 10px 18px 7px;
    background: rgb(231, 221, 253);
    font-size: 12px;
    font-weight: 600;
    color: rgb(90, 3, 185);
    margin-left:0px;
    margin-top:20px;
    font-family: 'Circular Std Bold';
    display:inline;
    
  
  }
  }