.getcoins{
    background-color: rgb(236, 236, 236);
}

.hero-image{
margin:10%;
}

h1.getcoins{
    padding-top: 20px;;
}