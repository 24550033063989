.side-bar{
  position: fixed;
    z-index: 100;
    left: 20px;
    top: 50%;
    transform: translateY(0%);
    width: 70px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    border-radius: 50%;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: rgb(209 230 255 / 57%) 0px 1px 0px 0px;
      padding-left:0px;
      background-color: #ffffff;
      color: rgb(0, 132, 232);

}

.side-bar img{
  width:30px;
  height:20px;
  object-fit: fill;
}
.side-bar:hover{

box-shadow: rgb(209 230 255 / 57%) 0px 1px 24px 1px;

}