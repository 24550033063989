.card{
    border-radius: 28px;
      flex:1;
      width: 90%;
      margin: auto;
      height:450px;
      box-sizing: border-box;
    overflow: hidden;
      position: relative;
      display: block;
      object-fit: contain;
      flex:1;
      margin-top: 30px;
      /* padding:20px; */
     box-shadow: rgb(209 230 255 / 57%) 0px 12px 12px 1px;
    
  }


  .image-container-backpack{
    background-color: rgb(156, 193, 194);
 
  }
  .image-container-getcoins{
    background-color: rgb(110, 110, 110);
 background: #11161e; 
  }
  .image-container-chargergogo{
    background-color: rgb(250, 250, 250);
 background: #89bf42;
  }
  .image-container-OD{
      background-color: rgb(318, 205, 180);
    }
  
  .thumbnail-image{
  height:100%;
  width:90%;
  object-fit: contain;
  display:flex;
  margin-left:auto;
  margin-right:auto;
  }

  
  .thumbnail-image-small{
    height:100%;
    width:75%;
    object-fit: contain;
    display:flex;
    margin-left:auto;
    margin-right:auto;
    }

  .thumbnail-logo{
    width:50%;
    height:100%;
    object-fit: contain;
    display:flex;
    margin-left:auto;
    margin-right:auto;


  }
  .image-container-api{
    min-width: 100%;
    height:100%;
    }
    

.card-left{
  width: 66%;
  max-width: 66%;
  height:100%;
  float:left;
  display:flex;
  
}

  .card-right{
    background-color: #fdfdfd;
    width:34%;
    height: 100%;
    justify-content: center;
    align-items: center;
    float: right;
    display:flex;
  }
  
  .image-detail-container{
  
    display:flex;
    justify-content: center;
    align-items: center;

    height: 50%;
    width:100%;
   
    
  }
  .title{
    text-align:center;
    justify-content: center;
    
  }


  .title{
    color: #535353;
    font-size:16px;
    font-weight: 100;
    letter-spacing: 2px;
    letter-spacing: 0.50px;
 }


 .card-title{
  color: #535353;
  font-size:20px;
  letter-spacing: 2px;
  text-align:center;
  font-family: 'Circular Std Bold';
  letter-spacing: 0.50px;
  margin-bottom: 14px;
}

.title h1{

  padding-left:0;

}
  
 
.card-subtitle{
    color: #aaaaaa;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    opacity: 100;
    text-align: center;
    /* line-height: 1.5%; */
    font-family: 'Circular Std Bold';



  }

  .card-description{
    text-align: center;
    font-size: 16px;
    text-transform: none;
    margin-bottom: 16px;
    font-family: 'Circular Std Bold';

  }
  .card-tag{
    border-radius: 15px;
    padding: 10px 14px 7px;
    background: rgb(219, 240, 255);
    color: rgb(0, 132, 232);
    width:auto;
    font-size: 12px;
    margin-top:10px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Circular Std Bold';
    
  }

  .card-tag-layout{
    display:flex;
    margin-left: auto;
    margin-right: auto;
  }


card-span{
  color:#666666;
  font-weight: 200;
  text-transform: initial;
  font-size: 16px;
  /* color: #aaaaaa; */
  line-height: 20px;
  text-align: center;
  background-color: yellow;
  align-items: center;
  justify-content: center;

}












/*Responsive Tablet */
@media screen and (min-width:768px)and ( max-width: 803px) {
 




}



@media screen and (max-width: 767px) {

.card{
  height:50%
}

.card-tag{
  font-size: 10px;
}

.image-container-chargergogo{
  height:100%;
  width:60%;
  position: absolute;
}
.image-container-getcoins{
  height:100%;
  width:60%;
  position: absolute;
}
  .image-container-backpack{
    height:100%;
    width:60%;
    position: absolute;
  }
  .image-container-OD{
    height:100%;
    width:60%;
    position: absolute;
  }





  .thumbnail{
    height:100%;
    max-width: 400px;
    display:flex;
    }

   img{
    width:10px;
   }




   .card-title{
    color: #535353;
    font-size:16px;
    font-weight: 100;
    letter-spacing: 2px;
    text-align:center;
    text-transform: uppercase;
    font-family: 'Circular Std Bold';
    letter-spacing: 0.50px;
    margin-bottom: 15px;
  
  
  }
  .card-subtitle{
    color: #aaaaaa;
    font-size: 1px;
    margin-bottom: 16px;
    opacity: 100;
    text-align: center;
    line-height: 1.5%;


    
  }
   
card-span{
  color:#666666;
  font-weight: 200;
  text-transform: initial;
  font-size: 14px;
  /* color: #aaaaaa; */
  line-height: 20px;
  text-align: center;
}

}
